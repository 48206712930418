import React from "react";
import { Unstable_Grid2 as Grid } from "@mui/material";
import BrandItem from "./BrandItem.js";

const MenuSection = (props) => {
  return (
    <Grid container xs={12} spacing={0}>
      <Grid xs={12}>
        <div id="admin-input-container">
          {Object.entries(
            props.data
              .filter((item) => item.active)
              .reduce((prev, curr) => {
                prev[curr[props.itemType]]
                  ? prev[curr[props.itemType]].push(curr)
                  : (prev[curr[props.itemType]] = [curr]);
                return prev;
              }, {})
          ).map(([key, value]) => {
            if (value.length > 0) {
              return <BrandItem item={value} identifier={key} />;
            }
            return null;
          })}
        </div>
      </Grid>
    </Grid>
  );
};

export default MenuSection;
