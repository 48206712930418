import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import "./menus.css";

const MenuButton = (item) => {
  return (
    <Link to={item.to} className="round-boys">
      {item.title}
    </Link>
  );
};

export default MenuButton;
