import React, { useEffect, useState } from "react";
import { db } from "../firebase.js";
import { ref, onValue } from "firebase/database";
import { Box, CssBaseline } from "@mui/material";
import { dbPath } from "../configs/dbConfig.js";
import Breadcrumbs from "../components/Breadcrumbs.js";
import MenuHeader from "../components/MenuHeader.js";
import SidebarDrawer from "../components/SidebarDrawer.js";
import MenuSection from "../components/MenuSection.js";

export default function JuiceMenu() {
  const [juices, setJuices] = useState([]);

  useEffect(() => {
    onValue(ref(db, `/${dbPath}/Juice/`), (snapshot) => {
      setJuices([]);
      const data = snapshot.val();
      if (data !== null) {
        Object.values(data).map((juice) => {
          return setJuices((oldArray) => [...oldArray, juice]);
        });
      }
    });
  }, []);

  const brandItemCount = {};
  juices
    .filter((juice) => juice.active)
    .forEach(({ juice }) => {
      if (brandItemCount[juice]) {
        brandItemCount[juice] += 1;
      } else {
        brandItemCount[juice] = 1;
      }
    });

  const sortedBrands = Object.keys(brandItemCount).sort((a, b) => {
    return brandItemCount[b] - brandItemCount[a];
  });

  const popularBrands = sortedBrands.slice(0, 5);

  return (
    <Box className="sidebar-container" sx={{ display: "flex" }}>
      <CssBaseline />
      {/* Header + Sidebar */}
      <MenuHeader menuTitle={"Juice Menu"} />
      <SidebarDrawer
        toolbarTitle={"Good Guy Vapes - Lancaster"}
        link={popularBrands}
      />
      <Box className="homepage" component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Breadcrumbs crumb={"Juice"} />
        {/* Inventory */}
        <MenuSection data={juices} itemType="juice" />
      </Box>
    </Box>
  );
}
