import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCydQOjtGS3g_YPqW-0V1pPTOmlZ2JKnYE",
  authDomain: "vaportrail-4893f.firebaseapp.com",
  databaseURL: "https://vaportrail-4893f-default-rtdb.firebaseio.com",
  projectId: "vaportrail-4893f",
  storageBucket: "vaportrail-4893f.appspot.com",
  messagingSenderId: "826379661143",
  appId: "1:826379661143:web:8ea37676d3a36709d25812",
  measurementId: "G-2TRNVHYMZ4"  
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const auth = getAuth();
