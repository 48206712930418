import React, { useEffect, useState } from "react";
import {
  Avatar,
  Drawer,
  List,
  FormControl,
  Stack,
  Toolbar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  ListItemIcon,
  Collapse,
  Unstable_Grid2 as Grid,
  useTheme,
  Box,
  OutlinedInput,
  InputLabel,
  Select,
  Chip,
} from "@mui/material";
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Delete as DeleteIcon,
  ExpandLess,
  ExpandMore,
  Inbox as InboxIcon,
  Settings as SettingsIcon,
  Home as HomeIcon,
  HorizontalRule as HorizontalRuleIcon,
  Logout as SignoutIcon,
} from "@mui/icons-material";
import { signOut } from "firebase/auth";
import { auth, db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { Accordion, Button } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import { uid } from "uid";
import { set, ref, onValue, remove, update } from "firebase/database";
import colorConfigs from "../configs/colorConfigs";
import { ReactComponent as LogoutIcon } from "../assets/sign-out.svg";
import GoodGuyThumb from "../assets/GoodGuyThumb.png";
import { dbPath } from "../configs/dbConfig.js";

export default function Disposables() {
  const [disposable, setDisposable] = useState("");
  const [item, setItem] = useState("");
  const [size, setSize] = useState("");
  const [description, setDescription] = useState("");
  const [active, setActive] = useState(true);
  const [disposables, setDisposables] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [tempUidd, setTempUidd] = useState("");
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const theme = useTheme();
  const [flavors, setFlavors] = useState([]);
  const [flavorTypes, setFlavorTypes] = useState([
    "Mint/Menthol",
    "Pastry/Cake",
    "Custard",
    "Berry",
    "Tropical",
    "Fruity",
    "Cereal",
    "Cookie",
    "Tobacco",
    "Flavorless",
  ]);
  const [sizes, setSizes] = useState([]);
  const [sizeTypes, setSizeTypes] = useState([
    "30mL",
    "50mL",
    "60mL",
    "100mL",
    "150mL",
    "200mL",
    "Other",
  ]);
  const [strengths, setStrengths] = useState([]);
  const [strengthTypes, setStrengthTypes] = useState([
    "0mg",
    "3mg",
    "6mg",
    "12mg",
    "24mg",
    "35mg",
    "48mg",
    "50mg",
    "55mg",
    "Other",
  ]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleFlavorChange = (event) => {
    const {
      target: { value },
    } = event;
    setFlavors(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleSizeChange = (event) => {
    const {
      target: { value },
    } = event;
    setSizes(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleStrengthChange = (event) => {
    const {
      target: { value },
    } = event;
    setStrengths(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        // read
        onValue(ref(db, `/${dbPath}/Disposables`), (snapshot) => {
          setDisposables([]);
          const data = snapshot.val();
          if (data !== null) {
            Object.values(data).map((cartridge) => {
              setDisposables((oldArray) => [...oldArray, cartridge]);
            });
          }
        });
      } else if (!user) {
        navigate("/");
      }
    });

  }, []);
  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  // add
  const writeToDatabase = (e) => {
    const uidd = uid();
    set(ref(db, `/${dbPath}/Disposables/${uidd}`), {
      disposable: disposable,
      category: "disposables",
      item: item,
      size: size,
      description: description,
      active: active,
      flavors: flavors,
      sizes: sizes,
      strengths: strengths,
      uidd: uidd,
    });

    setActive(true);
    setDisposable("");
    setItem("");
    setSize("");
    setDescription("");
    setFlavors([]);
    setSizes([]);
    setStrengths([]);

    e.preventDefault();
    console.log("You clicked submit.");
  };

  useEffect(() => {
    setActive(active);
  }, [active]);

  const handleActivation = (uid) => {
    update(ref(db, `/${dbPath}/Disposables/${uid}`), {
      active: active,
    });
    setActive(!active);
  };

  // delete
  const handleDelete = (uid) => {
    remove(ref(db, `/${dbPath}/Disposables/${uid}`));
  };

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className="homepage">
      <Grid container xs={12} spacing={0}>
        <Grid container md={4} id="admin-sidebar-container">
          <Grid>
            <Drawer
              sm={12}
              variant="permanent"
              sx={{
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  borderRight: "0px",
                  backgroundColor: colorConfigs.sidebar.bg,
                  color: colorConfigs.sidebar.color,
                },
              }}
            >
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  backgroundColor: colorConfigs.sidebar.bg,
                }}
                component="nav"
                subheader={
                  <ListSubheader component="div" id="sidebar-subheader">
                    VaporTrail Admin
                  </ListSubheader>
                }
              >
                <Toolbar sx={{ marginBottom: "20px" }}>
                  <Stack
                    sx={{ width: "100%" }}
                    direction="row"
                    justifyContent="center"
                  >
                    <Avatar src={GoodGuyThumb} />
                  </Stack>
                </Toolbar>
                <ListItemButton>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText className="sidebar-nav-link" primary="Home" />
                </ListItemButton>
                <ListItemButton onClick={handleClick}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="sidebar-nav-link"
                    primary="Inventory"
                  />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/inventory/cartridges"
                      sx={{ pl: 4 }}
                      selected={false}
                    >
                      <ListItemIcon>
                        <HorizontalRuleIcon />
                      </ListItemIcon>
                      <ListItemText
                        className="sidebar-nav-link"
                        primary="Cartridges"
                      />
                    </ListItemButton>
                    <ListItemButton
                      component={Link}
                      to="/inventory/disposables"
                      sx={{ pl: 4 }}
                      selected={true}
                    >
                      <ListItemIcon>
                        <HorizontalRuleIcon />
                      </ListItemIcon>
                      <ListItemText
                        className="sidebar-nav-link"
                        primary="Disposables"
                      />
                    </ListItemButton>
                    <ListItemButton
                      component={Link}
                      to="/inventory/juice"
                      sx={{ pl: 4 }}
                      selected={false}
                    >
                      <ListItemIcon>
                        <HorizontalRuleIcon />
                      </ListItemIcon>
                      <ListItemText
                        className="sidebar-nav-link"
                        primary="Juice"
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
                <ListItemButton>
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="sidebar-nav-link"
                    primary="Settings"
                  />
                </ListItemButton>
                <ListItemButton id="sidebar-signout">
                  <ListItemIcon>
                    <SignoutIcon />
                  </ListItemIcon>
                  <ListItemText className="sidebar-nav-link" primary="Logout" />
                </ListItemButton>
                <Grid>
                  <div className="footer-image-wrapper d-flex align-items-end justify-content-center">
                    <img src={GoodGuyThumb} className="footer-image" />
                  </div>
                </Grid>
              </List>
            </Drawer>
          </Grid>
        </Grid>
        {/* Inventory Maintenance */}
        <Grid
          container
          xs={12}
          sm={8}
          spacing={0}
          id="admin-inventory-container"
        >
          <h1>Disposables Menu</h1>
          <div>
            <Grid container xs={12} spacing={0}>
              <Grid
                container
                spacing={2}
                columns={{ xs: 2, sm: 12, md: 12 }}
                xs={12}
                id="admin-inventory-input"
                className="menu-container"
              >
                <Grid xs={12}>
                  <h1>Add Item</h1>
                </Grid>
                <Grid xs={12} md={6}>
                  <input
                    required={true}
                    className="color"
                    type="text"
                    placeholder="Brand Name"
                    value={disposable}
                    onChange={(e) => setDisposable(e.target.value)}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <input
                    required={true}
                    className="color"
                    type="text"
                    placeholder="Item Name"
                    value={item}
                    onChange={(e) => setItem(e.target.value)}
                  />
                </Grid>
                <Grid xs={12} md={12}>
                  <textarea
                    className="color-textarea"
                    type="text"
                    placeholder="Item Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Grid>
                <Grid xs={12} md={12}>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="flavors-chip-label">Flavors</InputLabel>
                    <Select
                      labelId="flavors-multiple-chip-label"
                      id="flavors-multiple-chip"
                      multiple
                      value={flavors}
                      onChange={handleFlavorChange}
                      input={
                        <OutlinedInput
                          id="flavors-select-multiple-chip"
                          label="Chip"
                        />
                      }
                      renderValue={(flavors) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {flavors.map((flavor) => (
                            <Chip key={flavor} label={flavor} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {flavorTypes.map((flavorType) => (
                        <Chip
                          key={flavorType}
                          value={flavorType}
                          label={flavorType}
                        ></Chip>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={12} md={12}>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="size-chip-label">Size</InputLabel>
                    <Select
                      labelId="size-multiple-chip-label"
                      id="size-multiple-chip"
                      multiple
                      value={sizes}
                      onChange={handleSizeChange}
                      input={
                        <OutlinedInput
                          id="size-select-multiple-chip"
                          label="Chip"
                        />
                      }
                      renderValue={(sizeTypes) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {sizeTypes.map((size) => (
                            <Chip key={size} label={size} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {sizeTypes.map((size) => (
                        <Chip key={size} value={size} label={size}></Chip>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={12} md={12}>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="strength-chip-label">Strength</InputLabel>
                    <Select
                      labelId="strength-multiple-chip-label"
                      id="strength-multiple-chip"
                      multiple
                      value={strengths}
                      onChange={handleStrengthChange}
                      input={
                        <OutlinedInput
                          id="strength-select-multiple-chip"
                          label="Chip"
                        />
                      }
                      renderValue={(strengthTypes) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {strengthTypes.map((strength) => (
                            <Chip key={strength} label={strength} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {strengthTypes.map((strength) => (
                        <Chip
                          key={strength}
                          value={strength}
                          label={strength}
                        ></Chip>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid>
                  <Button variant="success" onClick={writeToDatabase}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={12} spacing={2}>
              <Grid xs={12}>
                <div id="admin-input-container">
                  {Object.entries(
                    disposables.reduce((prev, curr) => {
                      prev[curr.disposable]
                        ? prev[curr.disposable].push(curr)
                        : (prev[curr.disposable] = [curr]);
                      return prev;
                    }, {})
                  ).map(([key, value]) => {
                    if (value !== null) {
                      return (
                        <Grid
                          xs={12}
                          spacing={2}
                          className="menu-container"
                          id={key.replace(/ /g, "")}
                        >
                          <Grid xs={12}>
                            <h1>{key}</h1>
                          </Grid>
                          {value.map(
                            ({
                              item,
                              description,
                              flavors,
                              sizes,
                              strengths,
                              uidd,
                              active,
                            }) => (
                              <div>
                                <Grid container xs={12}>
                                  <Grid xs={12}>
                                    <Accordion className="item-accordion">
                                      <Accordion.Item
                                        className={flavors}
                                        eventKey={uidd}
                                      >
                                        <Accordion.Header>
                                          <h2>{item}</h2>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          <p>{description}</p>
                                          <p>Flavor Notes: </p>
                                          {flavors?.map((flavor) => (
                                            <Chip label={flavor} />
                                          ))}
                                          <p>Sizes Available: </p>
                                          {sizes?.map((size) => (
                                            <Chip label={size} />
                                          ))}
                                          <p>Strengths Available: </p>
                                          {strengths?.map((strength) => (
                                            <Chip label={strength} />
                                          ))}
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    </Accordion>
                                  </Grid>
                                </Grid>
                                <Grid xs={12} className="todo buttons">
                                  {active ? (
                                    <VisibilityIcon
                                      fontSize="large"
                                      onClick={() => handleActivation(uidd)}
                                      className="edit-button"
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      fontSize="large"
                                      onClick={() => handleActivation(uidd)}
                                      className="edit-button"
                                    />
                                  )}
                                  <DeleteIcon
                                    fontSize="large"
                                    onClick={() => handleDelete(uidd)}
                                    className="delete-button"
                                  />
                                </Grid>
                              </div>
                            )
                          )}
                        </Grid>
                      );
                    }
                  })}
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <LogoutIcon
        width="60px"
        onClick={handleSignOut}
        className="logout-icon"
      />
    </div>
  );
}
