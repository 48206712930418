import React from "react";
import { Toolbar } from "@mui/material";

const ToolbarItem = (props) => {
  return (
    <Toolbar sx={{ padding: `23px 0px` }} className="sidebar-tab">
      {props.title}
    </Toolbar>
  );
};
export default ToolbarItem;
