import React from "react";
import { List, ListItemButton, ListItemText, ListItem } from "@mui/material";

const SidebarItem = (props) => {
  return (
    <List className="sidebar-container">
      {props.link.map((brand, index) => (
        <ListItem key={brand} disablePadding>
          <ListItemButton to={`#${brand.replace(" ", "")}`}>
            <ListItemText className="sidebar-nav-link" primary={brand} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default SidebarItem;
