import React, { useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../firebase.js";
import { useNavigate } from "react-router-dom";
import "./welcome.css";
import GoodGuy from "../assets/GoodGuy.png";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const [registerInformation, setRegisterInformation] = useState({
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();

  // useEffect(() => {
  //   auth.onAuthStateChanged((user) => {
  //     if (user) {
  //       navigate("/inventory");
  //     }
  //   });
  // }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSignIn = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        navigate("/inventory/cartridges");
      })
      .catch((err) => alert(err.message));
  };

  const handleRegister = () => {
    if (registerInformation.email !== registerInformation.confirmEmail) {
      alert("Please confirm that email are the same");
      return;
    } else if (
      registerInformation.password !== registerInformation.confirmPassword
    ) {
      alert("Please confirm that password are the same");
      return;
    }
    createUserWithEmailAndPassword(
      auth,
      registerInformation.email,
      registerInformation.password
    )
      .then(() => {
        navigate("/inventory/cartridges");
      })
      .catch((err) => alert(err.message));
  };

  return (
    <div className="welcome">
      <img src={GoodGuy} className="GoodGuy" />
      <h1 id="welcome-header">VaporTrail</h1>
      <div className="login-register-container">
        <>
          <input
            type="email"
            placeholder="Email"
            onChange={handleEmailChange}
            value={email}
          />
          <input
            type="password"
            onChange={handlePasswordChange}
            value={password}
            placeholder="Password"
          />
          <button className="sign-in-register-button" onClick={handleSignIn}>
            Sign In
          </button>
        </>
      </div>
    </div>
  );
}
