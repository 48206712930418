import React from "react";
import { Unstable_Grid2 as Grid } from "@mui/material";
import MenuItem from "./MenuItem.js";

const BrandItem = (props) => {
  return (
    <Grid
      xs={12}
      spacing={1}
      className="menu-container"
      id={props.identifier.replace(/ /g, "")}
    >
      <Grid xs={12}>
        <h1>{props.identifier}</h1>
      </Grid>
      {props.item.map(
        ({ item, description, flavors, sizes, strengths, uidd, active }) => {
          if (active) {
            return (
              <div>
                <MenuItem
                  item={item}
                  description={description}
                  flavors={flavors}
                  sizes={sizes}
                  strengths={strengths}
                  uidd={uidd}
                  active={active}
                />
              </div>
            );
          }
          return null;
        }
      )}
    </Grid>
  );
};

export default BrandItem;
