import React from "react";
import {
  Toolbar,
  AppBar,
  Typography,
  useMediaQuery
} from "@mui/material";

const MenuHeader = (props) => {
  const smallBreakpoint = useMediaQuery("(max-width:700px)");
  const drawerWidth = smallBreakpoint ? 0 : 300;

  return (
    <AppBar
      position="fixed"
      sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
    >
      <Toolbar className="toolbar-container">
        <Typography
          sx={{ fontFamily: `forma-djr-display`, fontWeight: `600` }}
          variant="h2"
          noWrap
          component="div"
        >
          {props.menuTitle}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default MenuHeader;
