import React, { useEffect, useState } from "react";
import { db } from "../firebase.js";
import { ref, onValue } from "firebase/database";
import { Box, CssBaseline } from "@mui/material";
import { dbPath } from "../configs/dbConfig.js";
import Breadcrumbs from "../components/Breadcrumbs.js";
import MenuHeader from "../components/MenuHeader.js";
import SidebarDrawer from "../components/SidebarDrawer.js";
import MenuSection from "../components/MenuSection.js";

export default function DisposableMenu() {
  const [disposables, setDisposables] = useState([]);

  useEffect(() => {
    onValue(ref(db, `/${dbPath}/Disposables/`), (snapshot) => {
      setDisposables([]);
      const data = snapshot.val();
      if (data !== null) {
        Object.values(data).map((disposable) => {
          return setDisposables((oldArray) => [...oldArray, disposable]);
        });
      }
    });
  }, []);

  const brandItemCount = {};
  disposables
    .filter((disposable) => disposable.active)
    .forEach(({ disposable }) => {
      if (brandItemCount[disposable]) {
        brandItemCount[disposable] += 1;
      } else {
        brandItemCount[disposable] = 1;
      }
    });

  const sortedBrands = Object.keys(brandItemCount).sort((a, b) => {
    return brandItemCount[b] - brandItemCount[a];
  });

  const popularBrands = sortedBrands.slice(0, 5);

  return (
    <Box className="sidebar-container" sx={{ display: "flex" }}>
      <CssBaseline />
      {/* Header + Sidebar */}
      <MenuHeader menuTitle={"Disposable Menu"} />
      <SidebarDrawer
        toolbarTitle={"Good Guy Vapes - Lancaster"}
        link={popularBrands}
      />
      <Box className="homepage" component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Breadcrumbs crumb={"Disposables"} />
        {/* Inventory */}
        <MenuSection data={disposables} itemType="disposable" />
      </Box>
    </Box>
  );
}
