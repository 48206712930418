import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import Login from "./pages/Login";
import DisposableMenu from "./pages/DisposableMenu";
import CartridgeMenu from "./pages/CartridgeMenu";
import JuiceMenu from "./pages/JuiceMenu";
import Menus from "./pages/Menus";
import Cartridges from "./pages/Cartridges";
import Settings from "./pages/Settings";
import Disposables from "./pages/Disposables";
import Juice from "./pages/Juice";
import PrivateRoute from "./routing/PrivateRoute";
import "./App.css";
import "./pages/homepage.css";
import "./pages/JuiceMenu.css";
import "./pages/menu.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route index element={<Menus />} />
          <Route path="/menus" element={<Menus />} />
          <Route path="/cartridges" element={<CartridgeMenu />} />
          <Route path="/disposables" element={<DisposableMenu />} />
          <Route path="/juice" element={<JuiceMenu />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/inventory/cartridges"
            element={
              <PrivateRoute>
                <Cartridges />
              </PrivateRoute>
            }
          />
          <Route
            path="/inventory/disposables"
            element={
              <PrivateRoute>
                <Disposables />
              </PrivateRoute>
            }
          />
          <Route
            path="/inventory/juice"
            element={
              <PrivateRoute>
                <Juice />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
