import React from "react";
import { Chip, Grid } from "@mui/material";
import Accordion from "react-bootstrap/Accordion";

const MenuItem = ({
  item,
  description,
  flavors,
  sizes,
  strengths,
  uidd,
  active,
}) => {
  return (
    <Grid container xs={12}>
      <Grid xs={12}>
        <Accordion className="item-accordion">
          <Accordion.Item className={flavors} eventKey={uidd}>
            <Accordion.Header>
              <div className="item-header">
                <h3>{item}</h3>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Grid container xs={12} spacing={1}>
              <p>Description: {description}</p>
                <Grid xs={12}>
                  <h4>Flavors:</h4>
                  {flavors?.map((flavor) => (
                    <Chip label={flavor} key={flavor} />
                  ))}
                </Grid>
                <Grid xs={12}>
                  <h4>Sizes:</h4>
                  {sizes?.map((size) => (
                    <Chip label={size} key={size} />
                  ))}
                </Grid>
                <Grid xs={12}>
                  <h4>Strengths:</h4>
                  {strengths?.map((strength) => (
                    <Chip label={strength} key={strength} />
                  ))}
                </Grid>
              </Grid>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default MenuItem;
