import React from "react";
import { Stack } from "@mui/material";
import { Row, Col, Container } from "react-bootstrap";
import MenuButton from "./MenuButton";
import GoodGuyThumb from "../assets/GoodGuyThumb.png";
import "./menus.css";

const MenuList = [
  { to: "/cartridges", title: "Cartridges" },
  { to: "/disposables", title: "Disposables" },
  { to: "/juice", title: "Juice" },
];

export default function Menu() {
  return (
    <div className="menu">
      <Container fluid className="menu-wrapper">
        <Row>
          <Col />
          {/* Menu List */}
          <Col id="menus-container">
            <div id="menus-inner">
              <h1>Menus</h1>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                {MenuList.map((item) => (
                  <MenuButton key={item.to} to={item.to} title={item.title} />
                ))}
              </Stack>
            </div>
          </Col>
          <Col />
        </Row>
      </Container>
      <div className="good-guy-thumb-container">
        <img
          src={GoodGuyThumb}
          alt="Good Guy Thumb"
          className="good-guy-thumb"
        />
      </div>
    </div>
  );
}
