import React from "react";
import { Avatar, Drawer, Stack, useMediaQuery, Divider } from "@mui/material";
import GoodGuyThumb from "../assets/GoodGuyThumb.png";
import SidebarItem from "./SidebarItem.js";
import ToolbarItem from "./ToolbarItem.js";

const SidebarDrawer = (props) => {
  const smallBreakpoint = useMediaQuery("(max-width:700px)");
  const drawerWidth = smallBreakpoint ? 0 : 300;

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: `#332D2D`,
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <ToolbarItem title={props.toolbarTitle} />
      <Divider sx={{ borderColor: `#000` }} />
      <SidebarItem link={props.link} />
      <Divider sx={{ borderColor: `#000` }} />
      <Stack
        sx={{ width: "100%" }}
        alignItems="flex-end"
        direction="row"
        justifyContent="center"
        height="100%"
      >
        <Avatar
          sx={{ marginBottom: `25px`, width: 90, height: 90 }}
          src={GoodGuyThumb}
        />
      </Stack>
    </Drawer>
  );
};

export default SidebarDrawer;
