import React, { useState } from "react";
import {
  Avatar,
  Drawer,
  List,
  Stack,
  Toolbar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  ListItemIcon,
  Collapse,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  Inbox as InboxIcon,
  Settings as SettingsIcon,
  Home as HomeIcon,
  HorizontalRule as HorizontalRuleIcon,
  Logout as SignoutIcon,
} from "@mui/icons-material";
import { signOut, updatePassword } from "firebase/auth";
import { auth, db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { Accordion, Button } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import { uid } from "uid";
import { set, ref, onValue, remove, update } from "firebase/database";
import colorConfigs from "../configs/colorConfigs";
import { ReactComponent as LogoutIcon } from "../assets/sign-out.svg";
import GoodGuyThumb from "../assets/GoodGuyThumb.png";
import { dbPath } from "../configs/dbConfig.js";
import { useAuth } from "../contexts/AuthContext";
import * as firebase from 'firebase/app';

export default function Settings() {
    const [currentPassword, setCurrentPassword] = useState("");
    const [password, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const { currentUser, updatePassword, signOut } = useAuth();

    console.log(currentUser);
    const user = auth.currentUser;
    console.log("USER " + JSON.stringify(user));

    
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        alert(err.message);
      });
  };


  const handlePasswordChange = async (event) => {
    event.preventDefault();
    try {
      if (password !== confirmPassword) {
        setError("New password and confirmed password do not match.");
        return;
      }
      const { EmailAuthProvider: { credential } } = auth;
      await currentUser.reauthenticateWithCredential(credential(currentPassword));
      await updatePassword(password);
      alert("Password updated successfully!");
    } catch (e) {
      setError(e.message);
    }
  };

  const handleClick = () => {
    setOpen(!open);
  };
  
  return (
    <div className="homepage">
      <Grid container spacing={0}>
        <Grid container md={4} id="admin-sidebar-container">
          <Grid>
            <Drawer
              sm={12}
              variant="permanent"
              sx={{
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  borderRight: "0px",
                  backgroundColor: colorConfigs.sidebar.bg,
                  color: colorConfigs.sidebar.color,
                },
              }}
            >
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  backgroundColor: colorConfigs.sidebar.bg,
                }}
                component="nav"
                subheader={
                  <ListSubheader component="div" id="sidebar-subheader">
                    VaporTrail Admin
                  </ListSubheader>
                }
              >
                <Toolbar sx={{ marginBottom: "20px" }}>
                  <Stack
                    sx={{ width: "100%" }}
                    direction="row"
                    justifyContent="center"
                  >
                    <Avatar src={GoodGuyThumb} />
                  </Stack>
                </Toolbar>
                <ListItemButton>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText className="sidebar-nav-link" primary="Home" />
                </ListItemButton>
                <ListItemButton onClick={handleClick}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="sidebar-nav-link"
                    primary="Inventory"
                  />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/inventory/cartridges"
                      sx={{ pl: 4 }}
                      selected={false}
                    >
                      <ListItemIcon>
                        <HorizontalRuleIcon />
                      </ListItemIcon>
                      <ListItemText
                        className="sidebar-nav-link"
                        primary="Cartridges"
                      />
                    </ListItemButton>
                    <ListItemButton
                      component={Link}
                      to="/inventory/disposables"
                      sx={{ pl: 4 }}
                      selected={false}
                    >
                      <ListItemIcon>
                        <HorizontalRuleIcon />
                      </ListItemIcon>
                      <ListItemText
                        className="sidebar-nav-link"
                        primary="Disposables"
                      />
                    </ListItemButton>
                    <ListItemButton
                      component={Link}
                      to="/inventory/juice"
                      sx={{ pl: 4 }}
                      selected={false}
                    >
                      <ListItemIcon>
                        <HorizontalRuleIcon />
                      </ListItemIcon>
                      <ListItemText
                        className="sidebar-nav-link"
                        primary="Juice"
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
                <ListItemButton
                  component={Link}
                  to="/inventory/juice"
                  selected={true}
                >
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="sidebar-nav-link"
                    primary="Settings"
                  />
                </ListItemButton>
                <ListItemButton id="sidebar-signout">
                  <ListItemIcon>
                    <SignoutIcon />
                  </ListItemIcon>
                  <ListItemText className="sidebar-nav-link" primary="Logout" />
                </ListItemButton>
                <Grid>
                  <div className="footer-image-wrapper d-flex align-items-end justify-content-center">
                    <img src={GoodGuyThumb} className="footer-image" />
                  </div>
                </Grid>
              </List>
            </Drawer>
          </Grid>
        </Grid>
        {/* User Settings */}
        <Grid
          container
          xs={12}
          sm={8}
          spacing={0}
          id="admin-inventory-container"
        >
          <h1>User Settings</h1>
          <div>
            <Grid container xs={6} spacing={0}>
              <Grid
                container
                spacing={2}
                columns={{ xs: 2, sm: 12, md: 12 }}
                xs={12}
                id="admin-inventory-input"
                className="menu-container"
              >
                <Grid xs={12}>
                  <h1>Change Password</h1>
                </Grid>
                <Grid xs={12} md={12}>
                  <input
                    required={true}
                    className="color"
                    type="text"
                    placeholder="Current Password"
                    id="current-password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                </Grid>
                <Grid xs={12} md={12}>
                  <input
                    required={true}
                    className="color"
                    type="text"
                    placeholder="New Password"
                    id="new-password"
                    value={password}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </Grid>
                <Grid xs={12} md={12}>
                  <input
                    className="color"
                    type="text"
                    placeholder="Confirm New Password"
                    id="confirm-password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Grid>
                <Grid>
                  <Button variant="success" onClick={handlePasswordChange}>
                    Update Password
                  </Button>
                  {error && <div style={{ color: "red" }}>{error}</div>}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <LogoutIcon
        width="60px"
        onClick={handleSignOut}
        className="logout-icon"
      />
    </div>
  );
}
