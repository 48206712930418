import { Unstable_Grid2 as Grid } from "@mui/material";
import Breadcrumb from "react-bootstrap/Breadcrumb";

const Breadcrumbs = (props) => {
  return (
    <Grid xs={12} sm={12}>
      <Breadcrumb id="crumbs">
        <Breadcrumb.Item className="crumbs-item" href="/">
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item className="crumbs-item" href="/menus">
          Menus
        </Breadcrumb.Item>
        <Breadcrumb.Item className="crumbs-item" active>
          {props.crumb}
        </Breadcrumb.Item>
      </Breadcrumb>
    </Grid>
  );
}

export default Breadcrumbs;